import mixpanel from 'mixpanel-browser';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';
import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';
import { redirect } from '../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';

export function initModalCreateAccountEmail(modal) {
    // VARS
    const form = document.querySelector('[data-modal-funnel]');
    if (!form) return;
    const submitEmail = form.querySelector('[data-modal-create-account-submit]');
    const submitAgreeCheckbox = form.querySelector('[data-modal-processing-submit]');
    const userEmail = form.querySelector('[data-modal-create-account-email]');
    const agreeCheckbox = form.querySelector('[data-modal-processing-checkbox]');
    const fieldsSelector = {
        userEmail: '[data-modal-create-account-email]',
    };
    const classShowErrorMessage = 'is-error';
    let isValidForm = true;
    const mixpanelKey = document.querySelector('[data-mixpannel-key]')?.dataset.mixpannelKey;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmitForm, false);

    submitEmail.addEventListener('click', handleOnSubmitEmail, false);

    userEmail.addEventListener('focus', handleOnFocusInputEmail, false);

    document.addEventListener('keydown', handleOnPressEnter, false);

    agreeCheckbox.addEventListener('change', handleOnChangeAgreeCheckbox, false);

    // HANDLERS
    function handleOnSubmitEmail(event) {
        validateForm(event);
    }

    function handleOnSubmitForm(event) {
        event.preventDefault();
        redirect(this);
    }

    function handleOnFocusInputEmail() {
        hideErrorMessage(fieldsSelector.userEmail);
    }

    function handleOnPressEnter(event) {
        if (event.key === 'Enter' && document.activeElement.hasAttribute('data-modal-create-account-email')) {
            validateForm(event);
        }
    }

    function handleOnChangeAgreeCheckbox() {
        changeSubmitOnChangeAgreeCheckbox();
    }

    // METHODS & FUNCTIONS
    function validateForm(event) {
        validateUserEmail();

        if (isValidForm) {
            isValidForm && modal.open('processing');
        } else {
            const props = cloneDeep(globalEventProperties);
            event.preventDefault();
            isValidForm = true;

            if (mixpanelKey) {
                mixpanel.track('button_click', {
                    ...props,
                    button_name: 'Continue',
                    button_location: 'Create Account Modal',
                    button_color: 'orange',
                });
            }
        }
    }

    function validateUserEmail() {
        hideErrorMessage(fieldsSelector.userEmail);

        if (!userEmail.value.trim()) {
            const props = cloneDeep(globalEventProperties);
            showErrorMessage(fieldsSelector.userEmail, 'required');
            isValidForm = false;

            if (mixpanelKey) {
                mixpanel.track('button_click', {
                    ...props,
                    form_validation: 'Required',
                });
            }
        } else if (!isValidMail(userEmail.value.trim())) {
            const props = cloneDeep(globalEventProperties);
            showErrorMessage(fieldsSelector.userEmail, 'invalid');
            isValidForm = false;

            if (mixpanelKey) {
                mixpanel.track('button_click', {
                    ...props,
                    form_validation: 'Please, enter valid email',
                });
            }
        } else {
            hideErrorMessage(fieldsSelector.userEmail);
        }
    }

    function changeSubmitOnChangeAgreeCheckbox() {
        submitAgreeCheckbox.disabled = agreeCheckbox.checked !== true;
    }

    function hideErrorMessage(selector) {
        const field = document.querySelector(selector);
        const errors = field.closest('[data-input-wrapper]').querySelectorAll('[data-field-error]');

        field.classList.remove(classShowErrorMessage);
        [...errors].forEach((error) => {
            error.classList.remove(classShowErrorMessage);
        });
    }

    function showErrorMessage(selector, type) {
        const field = document.querySelector(selector);
        const error = field.closest('[data-input-wrapper]').querySelector(`[data-field-error="${type}"]`);

        field.classList.add(classShowErrorMessage);
        error.classList.add(classShowErrorMessage);
    }
}
